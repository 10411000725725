import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQrcode } from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";

const OrderRow = ({ order, indexKey, handleAssignModal }) => {
  // Function to capitalize first letter of each word
  const capitalizeStatus = (status) => {
    if (!status) return "N/A";

    return status
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };
  const getStatusStyle = (status) => {
    switch (status?.toLowerCase()) {
      case "completed":
        return {
          backgroundColor: "#c6e1c6",
          color: "#2c3338",
          border: "none",
        };
      case "processing":
        return {
          backgroundColor: "#c8d7e1",
          color: "#2c3338",
          border: "none",
        };
      case "failed":
        return {
          backgroundColor: "#eba3a3",
          color: "#2c3338",
          border: "none",
        };
      case "pending payment":
        return {
          backgroundColor: "#e5e5e5",
          color: "#2c3338",
          border: "none",
        };
      default:
        return {
          backgroundColor: "#e5e5e5",
          color: "#2c3338",
          border: "none",
        };
    }
  };
  return (
    <tr key={indexKey}>
      <td>{order.id}</td>
      <td>{order.order_id}</td>
      <td>
        <Button
          variant={order.is_production ? "success" : "secondary"}
          className="btn-sm"
          disabled
        >
          {order.qrcode_id}
          <hr className="my-1" />
          {order.is_production ? "Prod" : "Virtual"}
        </Button>
      </td>
      <td>{order.category_name}</td>
      <td>{order.order_item_name}</td>
      <td>{order.nickname}</td>
      <td>
        <ul style={{ paddingLeft: "1rem" }}>
          <li>
            <strong>Email:</strong> {order.meta_data.email}
          </li>
          <li>
            <strong>Phone:</strong> {order.meta_data.phone}
          </li>
        </ul>
      </td>
      {/* <td>{order.meta_data.phone}</td> */}
      <td>
        <ul style={{ paddingLeft: "1rem" }}>
          {Object.entries(order.shipping_address).map(([index, value]) => (
            <li key={index}>{`${index}: ${value}`}</li>
          ))}
        </ul>
      </td>
      <td>
        <ul style={{ paddingLeft: "1rem" }}>
          <li>
            Created at:
            <br />
            <Button variant={"secondary"} className="btn-sm custom-small-btn" disabled>
              {order.created_at}
            </Button>
          </li>
          <li>
            Assinged at:
            <br />
            <Button
              variant={order.assigned_date ? "success" : "secondary"}
              className="btn-sm custom-small-btn"
              disabled
            >
              {order.assigned_date ?? "N/A"}
            </Button>
          </li>
        </ul>
      </td>
      <td>
        <Button
          style={getStatusStyle(order.order_status)}
          className="btn-sm custom-small-btn"
          disabled
        >
          {capitalizeStatus(order.order_status) ?? "N/A"}
        </Button>
      </td>
      <td>{order.total_price ?? "N/A"}</td>
      <td>
        {/* ====== Assign btn */}
        <Button
          variant={order.assigned_to_production ? "secondary" : "success"}
          className="btn-sm"
          onClick={() => handleAssignModal(order)}
          disabled={order.assigned_to_production}
        >
          <FontAwesomeIcon icon={faQrcode} />{" "}
          {order.assigned_to_production ? "Assigned" : "QR Assign"}
        </Button>
      </td>
    </tr>
  );
};

export default OrderRow;
